<template>
  <el-dialog v-model="isVisible" title="Buscar Produto" width="700px">
    <el-form label-width="200px">
      <el-form-item label="Filtros">
        <el-input
          v-model="searchTerm.codigo"
          placeholder="Código do Produto"
          style="margin-bottom: 10px;"
        ></el-input>    
        <el-input
          v-model="searchTerm.produto"
          placeholder="Nome do Produto"
          style="margin-bottom: 10px;"
        ></el-input>
        <el-input
          v-model="searchTerm.ref_sistema"
          placeholder="Referência do sistema"
          style="margin-bottom: 10px;"
        ></el-input>  
        <el-button type="primary" @click="buscarProduto">Buscar</el-button>
        <el-button @click="limparFiltros">Limpar</el-button>
      </el-form-item>
      <el-form-item>
        <div v-if="produtos.length > 0">
          Encontrados {{ total_register_found }} registros, mostrando registros de {{ first_register }} a {{ first_register + produtos.length }}.
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="total_register_found"
                      :page-size="20"
                      @current-change="handlePageChange"
                    ></el-pagination>
        </div>
      </el-form-item>
      <el-table :data="produtos" style="width: 100%">
        <el-table-column prop="id" label="Código" width="100"></el-table-column>
        <el-table-column prop="produto" label="Nome"></el-table-column>
        <el-table-column prop="ref_sistema" label="Referência do Sistema"></el-table-column>
        <el-table-column label="Ações" width="120">
          <template #default="scope">
            <el-button
              type="primary"
              size="mini"
              @click="selecionarProduto(scope.row)"
            >
              Selecionar
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <template #footer>
      <el-button @click="closeModal">Fechar</el-button>
    </template>
  </el-dialog>
</template>

<script>
import axios from "axios";

const PAGE_LEN = 20;

export default {
  name: "BuscaProdutoModal",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: {
        produto: "",
        ncm: "",
        net: "",
      },
      produtos: [],
      page_len: PAGE_LEN,
      total_register_found: 0,
      first_register: 1,
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    async buscarProduto() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/busca/produto`,
          {
            params: {
              produto: this.searchTerm.produto,
              ref_sistema: this.searchTerm.ref_sistema,
              codigo: this.searchTerm.codigo,
              number_of_registers: this.page_len,
              first_register: this.first_register,
            },
          }
        );
        this.produtos = response.data.registers;
        this.total_register_found = response.data.count;       
        console.log(`Encontrados ${this.total_register_found} produtos encontrados:`, this.produtos);
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      }
    },
    selecionarProduto(produto) {
      this.$emit("produto-selecionado", produto);
      this.closeModal();
    },
    closeModal() {
      this.isVisible = false;
      this.searchTerm = { codigo: "", produto: "", ref_sistema: "", number_of_registers: 20 };  
      this.produtos = [];
    },
    handlePageChange(page) {
      this.first_register = (page - 1) * 20 + 1;
      this.buscarProduto();
    },
    limparFiltros() {
      this.searchTerm = { codigo: "", produto: "", ref_sistema: "", number_of_registers: 20 };
      this.produtos = [];
      this.total_register_found = 0;
      this.first_register = 1;
    },
  },
};
</script>

<style scoped>
/* Estilos adicionais */
</style>
