<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <!-- Card de Busca -->
        <div class="card mb-4">
          <div class="card-body">
            <h5 class="card-title">Busca de Rastreabilidade</h5>
            <form @submit.prevent="buscarDados">
              <div class="row">
                <!-- Número de Série -->
                <div class="col-md-4 mb-3">
                  <label for="numeroSerie" class="form-label">Número de Série</label>
                  <input type="text" id="numeroSerie" v-model="filtros.numeroSerie" class="form-control"
                    placeholder="Digite o número de série" />
                </div>

                <!-- PA -->
                <div class="col-md-4 mb-3">
                  <label for="pa" class="form-label">PA</label>
                  <input type="text" id="pa" v-model="filtros.pa" class="form-control" placeholder="Digite o PA" />
                </div>

                <!-- Produto 
                <div class="col-md-4 mb-3">
                  <label for="produto" class="form-label">Produto</label>
                  <input type="text" id="produto" v-model="filtros.produto" class="form-control"
                    placeholder="Digite o produto" />
                </div> -->
              </div>

              <div class="row">
                <!-- PV -->
                <div class="col-md-4 mb-3">
                  <label for="pv" class="form-label">PV</label>
                  <input type="text" id="pv" v-model="filtros.pv" class="form-control" placeholder="Digite o PV" />
                </div>

                <!-- OF -->
                <div class="col-md-4 mb-3">
                  <label for="of" class="form-label">OF</label>
                  <input type="text" id="of" v-model="filtros.of" class="form-control" placeholder="Digite o OF" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 text-end">
                  <button type="submit" class="btn btn-primary">Buscar</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="card">
          <!-- Cabeçalho do Card -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">{{ table_title }}</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">                  
                  <soft-button-primary @click="openAddModal" v-if="create_permission">Novo Registro</soft-button-primary>
                </div>
              </div>
            </div>
          </div>

          <!-- Corpo do Card -->
          <div class="px-4 pb-4 card-body">
            <vue-good-table :columns="columns" :rows="rastreabilidades" v-on:row-click="handleRowClick"
              :search-options="{ enabled: true }"
              :pagination-options="{ enabled: true, perPageDropdown: [10, 25, 50, 100] }" :sort-options="{
                enabled: true,
                initialSortBy: { field: 'id', type: 'desc' }
              }">
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para Edição ou Adição -->
    <EditarRastreabilidadeModal :id="editingId" v-model="isModalVisible" @saved="fetchRastreabilidades"
      @deleted="handleDeleted" />
  </div>
</template>

<script>
import axios from "axios";
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
import EditarRastreabilidadeModal from "./components/EditarRastreabilidadeModal.vue";
import { mapActions, mapGetters } from 'vuex';
import SoftButtonPrimary from "@/components/SoftButtonPrimary.vue";

export default {
  name: "Rastreabilidade",
  components: {
    VueGoodTable,
    EditarRastreabilidadeModal,
    SoftButtonPrimary,
  },
  data() {
    return {
      create_permission: false,
      rastreabilidades: [],
      isModalVisible: false,
      editingId: null,
      columns: [
        { label: "Número de Série", field: "id", sortable: true, type: 'number' },
        { label: "PA (Ponto de Acabado)", field: "pa", sortable: true },
        { label: "Produto", field: "produto", sortable: true },
        { label: "PV", field: "pv", sortable: true, type: 'number' },
        { label: "OF", field: "codigo_oferta", sortable: true },
        { 
          label: "Data de Envio", 
          field: "data_enviado", 
          sortable: true, 
          formatFn: (value) => value ? value : "NÃO INFORMADO"
        },
        { label: "Status da Garantia", field: "status_garantia", sortable: true },
      ],
      filtros: {
        numeroSerie: null,
        pa: null,
        produto: null,
        pv: null,
        of: null,
      },
      table_title: "Últimos registros de rastreabilidade",
    };
  },
  computed: {
    ...mapGetters({
      profile: 'profile/profile'
    }),
  },
  mounted() {
    this.getProfile().then(() => {
      console.log(this.profile);
      this.create_permission = this.profile.permissions.includes('create_rastreabilidade');
      this.create_permission += this.profile.permissions.includes('root');
    });
    this.fetchRastreabilidades();
  },
  methods: {
    ...mapActions('profile', ['getProfile']),
    handleRowClick(params) {
      this.openEditModal(params.row.id);
    },
    handleDeleted() {
      this.fetchRastreabilidades();
    },
    fetchRastreabilidades() {
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/rastreabilidade_friendly`, {
          params: {
            limit: 10,
            orderBy: 'id', // Usado diretamente sem definição de variável
            order: 'desc'
          }
        })

        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.rastreabilidades = response.data;
          } else {
            console.error("Nenhum registro de rastreabilidade encontrado.");
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar rastreabilidades:", error);
        });
    },
    openAddModal() {
      this.editingId = null;
      this.isModalVisible = true;
    },
    openEditModal(id) {
      this.editingId = id;
      this.isModalVisible = true;
    },
    deleteRastreabilidade(id) {
      axios
        .delete(`${process.env.VUE_APP_API_BASE_URL}/rastreabilidades/${id}`)
        .then(() => {
          this.fetchRastreabilidades();
        })
        .catch((error) => {
          console.error("Erro ao excluir registro de rastreabilidade:", error);
        });
    },
    async buscarDados() {
      try {
        this.filtros.numeroSerie = this.filtros.numeroSerie ? this.filtros.numeroSerie : null;
        this.filtros.pa = this.filtros.pa ? this.filtros.pa : null;
        //this.filtros.produto = this.filtros.produto ? this.filtros.produto : null;
        this.filtros.pv = this.filtros.pv ? this.filtros.pv : null;
        this.filtros.of = this.filtros.of ? this.filtros.of : null;
      
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/rastreabilidade_friendly`, {
          params: {
            id: this.filtros.numeroSerie,
            pa: this.filtros.pa,
            //produto: this.filtros.produto,
            pv: this.filtros.pv,
            codigo_oferta: this.filtros.of,
            limit: 1000,
          }
        });
        if (response.data) {
          if (response.data.length > 0)
            this.rastreabilidades = response.data;
          else
            this.rastreabilidades = [];
          if(this.filtros.numeroSerie || this.filtros.pa || this.filtros.pv || this.filtros.of)
            this.table_title = "Resultados da busca";
          else
            this.table_title = "Últimos registros de rastreabilidade";
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    }
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
