<template>
    <el-dialog id="rastreabilidade_details" v-model="isVisible" :title="modalTitle" width="1000px">
        <el-form label-width="220px">
            <el-form-item v-if="id" label="Número de Série">
                <el-input v-model="rastreabilidade.id" readonly></el-input>
            </el-form-item>
            <el-form-item v-if="id" label="Data de Geração">
                <el-date-picker v-model="rastreabilidade.data_geracao" type="date" readonly></el-date-picker>
            </el-form-item>
            <el-form-item label="Responsável pela Criação">
                <el-input v-model="responsavel_criacao" readonly></el-input>
            </el-form-item>
            <el-form-item label="Cliente">
                <el-input v-model="clienteNome" readonly placeholder="Selecione um cliente"></el-input>
                <soft-button-dark v-if="userHasEditPermission" width="200px" @click.prevent="openBuscaClienteModal"><span>Buscar Cliente</span></soft-button-dark>                
            </el-form-item>
            <el-form-item label="Produto">
                <el-input v-model="produtoNome" readonly placeholder="Selecione um produto"></el-input>
                <soft-button-dark v-if="userHasEditPermission" width="200px" @click.prevent="openBuscaProdutoModal"><span>Buscar Produto</span></soft-button-dark>                
            </el-form-item>
            <el-form-item label="PV">
                <el-input v-model="rastreabilidade.pv" :readonly="!userHasEditPermission"></el-input>
            </el-form-item>
            <el-form-item label="OF (Código da oferta)">
                <el-input v-model="rastreabilidade.of" :readonly="!userHasEditPermission"></el-input>
            </el-form-item>
            <el-form-item label="Ordem de Produção">
                <el-input v-model="rastreabilidade.op" :readonly="!userHasEditPermission"></el-input>
            </el-form-item>
            <el-form-item label="PA (Código NET)">
                <el-input v-model="rastreabilidade.codigo_net" readonly></el-input>
            </el-form-item>
            <el-form-item label="Referência do Sistema">
                <el-input v-model="rastreabilidade.ref_sistema" readonly></el-input>
            </el-form-item>
            <el-form-item label="Obra Alocada">
                <el-input v-model="rastreabilidade.obra_alocada" :readonly="!userHasEditPermission"></el-input>
            </el-form-item>
            <el-form-item label="Número da Fatura">
                <el-input v-model="rastreabilidade.n_fatura" :readonly="!userHasEditPermission"></el-input>
            </el-form-item>
            <el-form-item label="Data de Envio">
                <el-date-picker
v-model="rastreabilidade.data_enviado"
                    v-format-date="rastreabilidade.data_geracao" :readonly="!userHasEditPermission"></el-date-picker>
            </el-form-item>
            <el-form-item label="Garantia (dias)">
                <el-input v-model="rastreabilidade.garantia_dias" :readonly="!userHasEditPermission"></el-input>
            </el-form-item>
            <el-form-item label="Expira Garantia">
                <el-date-picker v-model="rastreabilidade.expira_garantia" type="date" :readonly="!userHasEditPermission"></el-date-picker>
            </el-form-item>
            <el-form-item label="Status da Garantia">
                <el-input v-model="rastreabilidade.status_garantia" :readonly="!userHasEditPermission"></el-input>
            </el-form-item>
            <el-form-item label="Condição da Garantia">
                <el-input v-model="rastreabilidade.condicao_garantia" :readonly="!userHasEditPermission"></el-input>
            </el-form-item>
        </el-form>

        <!-- Componentes de gerenciamento de arquivos -->
        <div style="display: flex; gap: 20px; margin-top: 20px;" v-if="this.id">
            <FileManager ref="projetoFileManager" :title="'Projeto'" :type="'projeto'" :delete-permission="userHasDeleteFilePermission" />
            <FileManager ref="testesFileManager" :title="'Testes e Ensaios'" :type="'teste'" :delete-permission="userHasDeleteFilePermission" />
            <FileManager ref="manutencaoFileManager" :title="'Manutenção'" :type="'manutencao'" :delete-permission="userHasDeleteFilePermission" />
            <FileManager ref="documentosFileManager" :title="'Documentos Gerais'" :type="'documentos'" :delete-permission="userHasDeleteFilePermission" />
        </div>
        <!-- Fim dos componentes -->

        <template #footer>
            <soft-button color="info" v-if="userHasCreatePermission&&(!id)" width="200px" @click="load_daft"><span>Carregar Rascunho</span></soft-button>
            <soft-button color="info" v-if="id" width="200px" @click="showHistory"><span>Histórico</span></soft-button>
            <soft-button color="success" v-if="userHasEditPermission" width="200px" @click="saveChanges"><span>Salvar</span></soft-button>
            <soft-button color="danger" v-if="userHasDeleteRegPermission&&(id)" width="200px" @click="confirmDelete"><span>Excluir</span></soft-button>
        </template>

        <!-- Modal de busca de cliente -->
        <busca-cliente-modal v-model="isBuscaClienteModalVisible" @cliente-selecionado="selecionarCliente" />

        <!-- Modal de busca de produto -->
        <busca-produto-modal v-model="isBuscaProdutoModalVisible" @produto-selecionado="selecionarProduto" />

        <!-- Modal de rascunhos -->
        <rastreabilidades-drafts v-model="isDraftsModalVisible" :reload="reloadDrafts" @draft-selecionado="selecionarDraft" @reloaded="draftsReloaded"/>

        <HistoryModal
v-model="isHistoryModalVisible" :rastreabilidade_id="id" :is-visible="isHistoryModalVisible"
            @update:is-visible="isHistoryModalVisible = $event" />

    </el-dialog>

</template>

<script>
import secure_api from "../../services/secure-api";
import axios from "axios";
import BuscaClienteModal from "./BuscaClienteModal.vue";
import BuscaProdutoModal from "./BuscaProdutoModal.vue";
import RastreabilidadesDrafts from "./RastreabilidadesDrafts.vue";
import HistoryModal from './HistoryModal.vue';
import FileManager from '../../components/FileManager.vue';
import showSwal from "/src/mixins/showSwal.js";
import Swal from 'sweetalert2';
import SoftButtonDark from "@/components/SoftButtonDark.vue";
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "EditarRastreabilidadeModal",
    components: {
        BuscaClienteModal,
        BuscaProdutoModal,
        HistoryModal,
        FileManager,
        RastreabilidadesDrafts,
        SoftButtonDark,
        SoftButton,
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        id: {
            type: Number,
            default: null,
        },
    },
    setup() {
        return {

        };
    },
    data() {
        return {
            rastreabilidade: this.createEmptyItem(),
            clienteNome: "",
            isBuscaClienteModalVisible: false,
            produtoNome: "",
            isBuscaProdutoModalVisible: false,
            responsavel_criacao: "",
            isHistoryModalVisible: false,
            userHasDeleteFilePermission: false,
            userHasDeleteRegPermission: false,
            userHasEditPermission: false,
            userHasCreatePermission: false,
            isDraftsModalVisible: false,
            loadedDraft: null,
            reloadDrafts: false,
        };
    },
    mounted() {
        console.log("Modal de edição de rastreabilidade montado");        
    },
    computed: {
        isVisible: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
        modalTitle() {
            return this.id ? `Rastreabilidade ${this.rastreabilidade.id}` : "Nova Rastreabilidade";
        },
        ...mapGetters({
            profile: 'profile/profile'
        }),
    },
    watch: {
        id: {
            immediate: true,
            handler(newId) {
                if (newId) {
                    this.fetchRastreabilidade(newId);
                } else {
                    this.rastreabilidade = this.createEmptyItem();
                }
            },
        },
    },
    methods: {
        ...mapActions('profile', ['getProfile']),
        draftsReloaded() {
            this.reloadDrafts = false;
        },
        fetchRastreabilidade(id) {
            console.log("Profile: ", this.profile);
            secure_api
                .get(`rastreabilidades/${id}`)
                .then((response) => {
                    this.rastreabilidade = response.data;
                    this.fetchCliente(this.rastreabilidade.cliente_id);
                    this.fetchProduto(this.rastreabilidade.produto_id);
                    this.fetchResponsavelCriacao(this.rastreabilidade.responsavel_criacao);
                    this.showFiles();
                })
                .catch((error) => {
                    console.error("Erro ao buscar rastreabilidade:", error);
                });
        },
        fetchCliente(id) {
            if (id) {
                axios
                    .get(`${process.env.VUE_APP_API_BASE_URL}/clientes/${id}`)
                    .then((response) => {
                        this.clienteNome = response.data.nome;
                    })
                    .catch((error) => {
                        console.error("Erro ao buscar cliente:", error);
                    });
            } else {
                this.clienteNome = "";
            }
        },
        fetchProduto(id) {
            if (id) {
                axios
                    .get(`${process.env.VUE_APP_API_BASE_URL}/produtos/${id}`)
                    .then((response) => {
                        this.produtoNome = response.data.produto;
                    })
                    .catch((error) => {
                        console.error("Erro ao buscar produto:", error);
                    });
            } else {
                this.produtoNome = "";
            }
        },
        fetchResponsavelCriacao(id) {
            if (id) {
                console.log("Buscando responsável pela criação:", id);
                secure_api
                    .get(`${process.env.VUE_APP_API_BASE_URL}/users/${id}`)
                    .then((response) => {
                        let __user = response.data.data
                        console.log(`Usuário ${id}:`, __user);
                        this.responsavel_criacao = __user.attributes.name;
                    })
                    .catch((error) => {
                        console.error("Erro ao buscar responsável pela criação:", error);
                    });
            } else {
                this.responsavel_criacao = "";
            }
        },
        loadProfile(){
            this.getProfile().then(() => {
                console.log("profile:", this.profile);
                this.responsavel_criacao = this.profile.name;
                this.rastreabilidade.responsavel_criacao = this.profile.id;
                this.checkUserPermissions();
            });
        },
        createEmptyItem() {      
            this.loadProfile();
            this.clienteNome = "";
            this.produtoNome = "";    
            return {
                id: null,
                data_geracao: null,
                responsavel_criacao: null,
                cliente_id: null,
                produto_id: null,
                pv: "",
                op: "",
                of: null,
                codigo_net: null,
                ref_sistema: "",
                obra_alocada: "",
                n_fatura: "",
                data_enviado: null,
                garantia_dias: null,
                expira_garantia: null,
                status_garantia: "",
                condicao_garantia: "",
            };

        },
        deleteWorkingDraft() {
            console.log("deleteWorkingDraft:", this.loadedDraft);
            if (this.loadedDraft) {
                secure_api.delete(`rastreabilidades_drafts/${this.loadedDraft.id}`)
                    .then(() => {
                        console.log("Rascunho excluído com sucesso.");
                    })
                    .catch((error) => {
                        console.log("Erro ao excluir rascunho:", error);
                    })
                    .finally(() => {
                        this.loadedDraft = null;
                        this.reloadDrafts = true;
                    });
            }
        },
        saveChanges() {
            // seta o campo responsavel_modificacao com o id do usuário atual
            this.rastreabilidade.responsavel_modificacao = parseInt(this.profile.id);
            const formattedData = {
                ...this.rastreabilidade,
                data_geracao: this.formatDate(this.rastreabilidade.data_geracao),
                data_enviado: this.formatDate(this.rastreabilidade.data_enviado),
                expira_garantia: this.formatDate(this.rastreabilidade.expira_garantia)
            };
            const request = this.id
                ? secure_api.put(`rastreabilidades/${this.id}`, formattedData)
                : secure_api.post('rastreabilidades', formattedData);
            
            this.isNew = !this.id;
            this.formattedData = formattedData;

            request
                .then(() => {
                    this.$emit("saved");
                    this.closeModal();
                    this.deleteWorkingDraft();
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Alterações salvas com sucesso.",
                    });                    
                })
                .catch((error) => {
                    console.error("Erro ao salvar alterações:", error);
                    if (this.isNew) {
                        let parentZIndex = Number(document.querySelector('.el-overlay').style.zIndex);
                        Swal.fire({
                            title: 'Dados incompletos',
                            html: 'Alguns campos obrigatórios não foram preenchidos:<br>' + 
                                  Object.entries(error.response.data.errors).map(([, value]) => `${value}`).join('<br>') + 
                                  '<br><br><h5>Deseja salvar como rascunho?</h5>',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Sim, salvar rascunho',
                            cancelButtonText: 'Cancelar',
                            didOpen: () => {
                                document.querySelector('.swal2-container').style.zIndex = parentZIndex+1;
                            }
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (this.loadedDraft) this.formattedData.id = this.loadedDraft.id;
                                const draft_request = this.loadedDraft                                    
                                    ? secure_api.put(`rastreabilidades_drafts/${this.loadedDraft.id}`, this.formattedData)
                                    : secure_api.post('rastreabilidades_drafts', this.formattedData);
                                draft_request
                                    .then(() => {
                                        this.closeModal();                                    
                                        showSwal.methods.showSwal({
                                            type: "success",
                                            message: "Rascunho salvo com sucesso.",
                                        });
                                    })
                                    .catch((error) => {
                                        showSwal.methods.showSwal({
                                            type: "error",
                                            message: `Erro ao salvar rascunho:\n ${error.response.data.message}`,                        
                                        });
                                    })
                                    .finally(() => {
                                        this.reloadDrafts = true;
                                    });
                            }
                        });
                    } else {
                        showSwal.methods.showSwal({
                            type: "error",
                            message: `Erro ao salvar alterações:\n ${error.response.data.message}`,                        
                        });
                    }
                });
        },
        showHistory() {
            console.log("showHistory, id: ", this.id);
            this.isHistoryModalVisible = true;
            HistoryModal.methods.fetchHistory(this.rastreabilidade.id);

        },
        closeModal() {
            this.isVisible = false;
        },
        openBuscaClienteModal() {
            this.isBuscaClienteModalVisible = true;
        },
        selecionarCliente(cliente) {
            this.rastreabilidade.cliente_id = cliente.id;
            this.clienteNome = cliente.nome;
            this.isBuscaClienteModalVisible = false;
        },
        openBuscaProdutoModal() {
            this.isBuscaProdutoModalVisible = true;
        },
        selecionarProduto(produto) {
            console.log("Produto selecionado:", produto, "detalhes: ref_sistema:", produto.ref_sistema, " codigo net:", produto.id);
            this.rastreabilidade.produto_id = produto.id;
            this.produtoNome = produto.produto;
            this.isBuscaProdutoModalVisible = false;
            this.rastreabilidade.ref_sistema = produto.ref_sistema;
            this.rastreabilidade.codigo_net = produto.id;
        },
        selecionarDraft(draft) {            
            this.loadedDraft = draft;
            this.rastreabilidade = { ...draft };
            this.rastreabilidade.id = null;
            this.clienteNome = draft.client;
            this.produtoNome = draft.product;
            this.isDraftsModalVisible = false;
            console.log("Draft selecionado:", this.loadedDraft);
        },
        formatDate(date) {
            if (!date) return null;
            const d = new Date(date);
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            const year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        showFiles() {
            this.$refs.projetoFileManager.loadFiles(this.id);
            this.$refs.testesFileManager.loadFiles(this.id);
            this.$refs.manutencaoFileManager.loadFiles(this.id);
            this.$refs.documentosFileManager.loadFiles(this.id);
        },
        checkUserPermissions() {            
            this.userHasEditPermission = this.checkPermission('edit_rastreabilidade') | this.checkPermission('root');
            this.userHasDeleteRegPermission = this.checkPermission('delete_rastreabilidade') | this.checkPermission('root');            
            this.userHasDeleteFilePermission = this.checkPermission('delete_file') | this.checkPermission('root');
            this.userHasCreatePermission = this.checkPermission('create_rastreabilidade') | this.checkPermission('root');            
        },
        checkPermission(permission) {           
            const userPermissions = this.profile.permissions;
            return userPermissions.includes(permission);
        },
        confirmDelete() {
            let parentZIndex = Number(document.querySelector('.el-overlay').style.zIndex);
            Swal.fire({
                title: 'Você tem certeza?',
                text: "Você não poderá reverter isso!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, excluir!',
                cancelButtonText: 'Cancelar',                
                didOpen: () => {
                    // Força o SweetAlert2 para frente após a inicialização
                    document.querySelector('.swal2-container').style.zIndex = parentZIndex+1;
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteRastreabilidade();
                }
            });
        },
        deleteRastreabilidade() {            
            secure_api
                .delete(`/rastreabilidades/${this.id}`)
                .then(() => {
                    this.$emit("deleted");
                    this.closeModal();
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Registro excluído com sucesso.",
                    });
                })
                .catch((error) => {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: `Erro ao excluir registro de rastreabilidade:\n ${error}`,
                    });
                });
        },
        load_daft() {
            this.isDraftsModalVisible = true;
        },
    },
};
</script>

<style scoped></style>