<template>
  <button
    class="btn soft-dark-btn mb-0" 
    :class="getClasses(variant, color, size, fullWidth, active)"
    :style="getStyles()"
    :disabled="isDisabled"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "SoftButtonDark",
  props: {
    color: {
      type: String,
      default: "dark", // 🔹 Padrão: dark
    },
    size: {
      type: String,
      default: "sm", // 🔹 Padrão: sm
    },
    variant: {
      type: String,
      default: "gradient", // 🔹 Padrão: gradient
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "auto", // 🔹 Permite definir a largura (ex: "150px", "50%", etc.)
    },
  },
  methods: {
    getClasses: (variant, color, size, fullWidth, active) => {
      let colorValue, sizeValue, fullWidthValue, activeValue;

      // Setting the button variant and color
      if (variant === "gradient") {
        colorValue = `bg-gradient-${color}`;
      } else if (variant === "outline") {
        colorValue = `btn-outline-${color}`;
      } else {
        colorValue = `btn-${color}`;
      }

      sizeValue = size ? `btn-${size}` : null;

      fullWidthValue = fullWidth ? `w-100` : null;

      activeValue = active ? `active` : null;

      return `${colorValue} ${sizeValue} ${fullWidthValue} ${activeValue}`;
    },
    getStyles() {
      return {
        width: this.fullWidth ? "100%" : this.width,
        margin: "0 8px", // Adiciona espaçamento vertical
      };
    },
  },
};
</script>

<style scoped>
/* Adiciona espaçamento entre botões consecutivos */
.soft-dark-btn {
  margin-right: 8px; /* Distância horizontal */
  margin-bottom: 8px; /* Distância vertical */
}
</style>