import { format } from 'date-fns';

export default {
  bind(el, binding) {
    // Checa se o valor passado é uma data válida antes de formatar
    const dateValue = new Date(binding.value);
    if (!isNaN(dateValue.getTime())) {
      // Aplica a formatação
      el.innerHTML = format(dateValue, 'yyyy-MM-dd');
    } else {
      console.warn(`Data inválida: ${binding.value}`);
    }
  },
  update(el, binding) {
    // Atualiza o valor formatado quando o valor mudar
    const dateValue = new Date(binding.value);
    if (!isNaN(dateValue.getTime())) {
      el.innerHTML = format(dateValue, 'yyyy-MM-dd');
    }
  }
};
