<template>
  <div id="sidenav-collapse-main" class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100">
    <ul class="navbar-nav">
      <li class="mt-3 nav-item">
        <h6
class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="$store.state.isRTL ? 'me-4' : 'ms-2'">
          Gestão do Sistema
        </h6>
      </li>
      <!--
      <li class="nav-item">
        <sidenav-collapse navText="Login" :to="{ name: 'Login' }">
          <template #icon>
            <document />
          </template>
</sidenav-collapse>
</li>
<li class="nav-item">
  <sidenav-collapse navText="Register" :to="{ name: 'Register' }">
    <template #icon>
            <spaceship />
          </template>
  </sidenav-collapse>
</li> -->
      <li class="nav-item">
        <sidenav-collapse nav-text="Dados Pessoais" :to="{ name: 'Profile' }">
          <template #icon>
            <customer-support />
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="isRootUser" class="nav-item">
        <sidenav-collapse nav-text="Usuários" :to="{ name: 'Users' }">
          <template #icon>
            <switches />
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <h6
class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="$store.state.isRTL ? 'me-4' : 'ms-2'">
          Sistemas
        </h6>
      </li>

      <li v-if="hasPermissions" class="nav-item">
        <sidenav-collapse nav-text="Clientes" :to="{ name: 'Clientes' }">
          <template #icon>
            <users />
          </template>
        </sidenav-collapse>
      </li>

      <li v-if="hasPermissions" class="nav-item">
        <sidenav-collapse nav-text="Produto" :to="{ name: 'Produtos' }">
          <template #icon>
            <box />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse nav-text="Rastreabilidade" :to="{ name: 'Rastreabilidade' }">
          <template #icon>
            <route />
          </template>
        </sidenav-collapse>
      </li>

    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
:class="cardBg" text-primary="Precisa de ajuda?" text-secondary="Documentação do sistema"
      route="https://teksea.net" label="Documentação" icon="ni ni-diamond" />
    >
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Switches from "../../components/Icon/Switches.vue";
import Users from "../../components/Icon/CustomerSupport.vue";
import Box from "../../components/Icon/Box3d.vue";
import Route from "../../components/Icon/Document.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "SidenavList",
  components: {
    SidenavCollapse,
    SidenavCard,
    CustomerSupport,
    Switches,    
    Users,
    Box,
    Route
  },
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "TekLegacy",
      controls: "dashboardsExamples",
      isActive: "active",
      hasPermissions : false,
      isRootUser: false,
    };
  },
  computed:{
    ...mapGetters({
            profile: 'profile/profile'
        }),
  },
  mounted() {
    try {
      this.getProfile().then(() => {
        if (this.profile.permissions) {
          this.hasPermissions = ['root', 'create_rastreabilidade']
          .some(permission => this.profile.permissions.includes(permission));   
          this.isRootUser = this.profile.permissions.includes('root');     
        }
      });
    } catch (error) {
      console.log(error);
    }

  },
  methods: {
    ...mapActions('profile', ['getProfile']),
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
