<template>
    <div class="file-manager">
        <div class="file-manager-header">
            <h6>{{ title }}</h6>
        </div>
        <div class="file-manager-body">
            <el-scrollbar>
                <ul class="file-list">
                    <li v-for="(file, index) in localFiles" :key="index" class="file-item">
                        <span>{{ file.name }}</span>
                        <div class="file-actions">
                            <button v-if="downlodPermission" @click="downloadFile(file)">
                                <i class="fas fa-download"></i>
                            </button>
                            <button v-if="deletePermission" @click="deleteFile(file)">
                                <i class="fas fa-trash button-red"></i>
                            </button>
                        </div>
                    </li>
                </ul>
            </el-scrollbar>
        </div>
        <div class="file-manager-footer">
            <div class="drop-zone" @drop.prevent="handleDrop" @dragover.prevent>
                <p>Arraste e solte arquivos aqui</p>
            </div>
        </div>
    </div>

    <confirm-dialog ref="deleteConfirmDialog" :title="'Deseja realmente excluir o arquivo?'" :text="'Essa ação não poderá ser desfeita'"/>
</template>

<script>
import axios from "axios";
import secure_api from "../services/secure-api";
import showSwal from "/src/mixins/showSwal.js";
import ConfirmDialog from './ConfirmDialog.vue';

export default {
    name: "FileManager",
    components: {
        ConfirmDialog,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: "documentos",
            validator: (value) => ["projeto", "teste", "manutencao", "documentos"].includes(value),
        },
        files: {
            type: Array,
            default: () => [],
        },
        rastreabilidadeId: {
            type: Number,
            required: true,
        },
        deletePermission: {
            type: Boolean,
            default: false,
        },
        downlodPermission: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            localRastreabilidadeId: this.rastreabilidadeId,
            localFiles: [...this.files],
        };
    },
    methods: {
        clear() {
            this.localFiles = [];
        },
        async loadFiles(rastreabilidade_id) {
            this.localRastreabilidadeId = rastreabilidade_id;
            try {
                this.clear();
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/files/rastreabilidade/${rastreabilidade_id}`);

                console.log(response);
                this.localFiles = response.data
                    .filter(file => file.type === this.type)
                    .map(file => ({
                        name: file.friendly_name,
                        url: `${process.env.VUE_APP_API_BASE_URL}/files/download/${rastreabilidade_id}/${file.type}/${file.filename}/${file.friendly_name}`,
                        id: file.id,
                    }));

                console.log("Arquivos carregados com sucesso:", this.localFiles);
            } catch (error) {
                console.log(`Não existem arquivos para a rastreabilidade ${rastreabilidade_id}`);
            }
        },
        async handleDrop(event) {
            const files = event.dataTransfer.files;
            console.log("Arquivos enviados:", files);

            if (!files.length) {
                console.warn("Nenhum arquivo foi arrastado.");
                return;
            }

            for (const file of files) {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("rastreabilidade_id", this.localRastreabilidadeId);
                formData.append("type", this.type);

                try {
                    const response = await secure_api.post(`files/upload`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    console.log("Upload bem-sucedido:", response.data);

                    const { friendly_name, filename } = response.data.file;
                    this.localFiles.push({
                        name: friendly_name,
                        url: `${process.env.VUE_APP_API_BASE_URL}/files/download/${this.localRastreabilidadeId}/${this.type}/${filename}/${friendly_name}`,
                        id: response.data.file.id,
                    });
                } catch (error) {
                    let message = "Erro ao fazer upload do arquivo.";
                    if (error.response && error.response.status === 403) {
                        message += " Você não tem permissão para fazer upload de arquivos.";
                    }
                    showSwal.methods.showSwal({ type: "Erro", message: message });

                }
            }
        },
        downloadFile(file) {
            console.log("Download mockup para:", file.url);
            // Mockup de download
            window.open(file.url, "_blank");
        },
        deleteFile(file) {
            this.$refs.deleteConfirmDialog.show(() => {
                this.finishDeleteFile(file);
            });
        },
        finishDeleteFile(file) {
            try {
                secure_api.delete(`files/${file.id}`)
                .then(() => {
                    this.localFiles = this.localFiles.filter(f => f.name !== file.name);
                    showSwal.methods.showSwal({ type: "success", message: "Arquivo excluído com sucesso." });
                })
                .error(() => {
                    showSwal.methods.showSwal({ type: "error", message: "Erro ao excluir o arquivo." });
                });
            } catch (error) {
                console.error("Erro ao excluir o arquivo:", error);
            }
        },
        watch: {
            files: {
                immediate: true,
                handler(newFiles) {
                    this.localFiles = [...newFiles];
                },
            },
        }
    },
};
</script>

<style scoped>
.file-manager {
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    height: 400px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.file-manager-header {
    background: #f0f0f0;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

.file-manager-body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
}

.file-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #eee;
}

.file-item:last-child {
    border-bottom: none;
}

.file-item span {
    flex: 1;
    /* Toma o espaço disponível */
    white-space: nowrap;
    /* Impede que o texto quebre */
    overflow: hidden;
    /* Esconde o texto que ultrapassar o limite */
    text-overflow: ellipsis;
    /* Adiciona "..." ao texto muito longo */
}

.file-actions {
    display: flex;
    gap: 10px;
    flex-shrink: 0;
    /* Impede que os botões sejam redimensionados */
}

.file-actions button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
    color: #007bff;
}

.file-actions button:hover {
    color: #0056b3;
}

.file-manager-footer {
    padding: 10px;
    background: #f7f7f7;
    text-align: center;
}

.drop-zone {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    color: #888;
    background: #fafafa;
    cursor: pointer;
}

.drop-zone:hover {
    background: #f0f0f0;
}

.button-red {
    color: #dc3545;
}
</style>
