<template>
    <el-dialog :visible="isVisible" title="Histórico de Modificações" width="1200px" @close="closeModal">
        <el-table :data="logs" style="width: 100%">
            <el-table-column prop="campo" label="Campo" width="200"></el-table-column>
            <el-table-column prop="valor_anterior" label="Valor Anterior" width="300"></el-table-column>
            <el-table-column prop="valor_novo" label="Valor Novo" width="300"></el-table-column>
            <el-table-column prop="modificado_por" label="Modificado Por" width="200"></el-table-column>
            <el-table-column prop="modificado_em" label="Modificado Em" width="200"></el-table-column>
        </el-table>
    </el-dialog>
</template>

<script>
import axios from "axios";

export default {
    name: "HistoryModal",
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        rastreabilidade_id: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            logs: [],
        };
    },
    watch: {
        rastreabilidade_id: {
            immediate: true,
            handler(id) {
                console.log("ID de rastreabilidade mudou para:", id);
                if (id) {
                    this.fetchHistory(id);
                }
            },
        },
        isVisible: {
            immediate: true,
            handler(visible) {
                console.log("Visibilidade do modal mudou para:", visible);
                if (visible && this.rastreabilidade_id) {
                    this.fetchHistory(this.rastreabilidade_id);
                }
            },
        },
    },
    methods: {
        fetchHistory(id) {           
            this.logs = [];
            axios            
                .get(`${process.env.VUE_APP_API_BASE_URL}/rastreabilidade_history/${id}`)
                .then((response) => {
                    console.log("tipo dos dados recebidos:", typeof response.data);
                    this.logs = response.data;                    
                })
                .catch((error) => {
                    console.error("Erro ao buscar histórico:", error);
                });
        },
        closeModal() {
            this.$emit("update:is-visible", false);
        }
    }
};
</script>

<style scoped>
/* Personalize o estilo se necessário */
</style>
