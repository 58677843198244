<template>
  <div>
    <BaseHeader title="Usuários" />
    <Card>
      <CardContent>
        <div class="px-4 pb-4 card-body">
          <vue-good-table :columns="columns" :rows="users" v-on:row-click="handleRowClick"
            :search-options="{ enabled: true }"
            :pagination-options="{ enabled: true, perPageDropdown: [10, 25, 50, 100] }" :sort-options="{
              enabled: true,
              initialSortBy: { field: 'name', type: 'desc' }
            }">
          </vue-good-table>
        </div>
      </CardContent>
    </Card>

    <!-- Modal para Edição de Usuário -->
    <el-dialog v-model="isModalVisible" title="Editar Usuário" width="600px">
      <el-form label-width="120px">
        <el-form-item label="Nome">
          <el-input v-model="selectedUser.name"></el-input>
        </el-form-item>
        <el-form-item label="Email">
          <el-input v-model="selectedUser.email"></el-input>
        </el-form-item>
        <el-form-item label="Função">
          <el-select v-model="selectedUser.role_id" placeholder="Selecione uma função">
            <el-option v-for="role in roles" :key="role.id" :label="role.name" :value="role.id" ></el-option>
          </el-select>
        </el-form-item>
        <soft-button-dark width="140px" @click.prevent="saveChanges"><span>Salvar</span></soft-button-dark>
        <soft-button-dark width="140px" @click.prevent="closeModal"><span>Cancelar</span></soft-button-dark>

        <el-divider></el-divider>

        <el-label>Gerenciamento de Senha</el-label>
        <el-form-item label="Nova Senha">
          <el-input v-model="selectedUser.new_password" type="password" placeholder="Digite a nova senha"></el-input>
        </el-form-item>
        <soft-button-dark @click.prevent="changePassword" v-if="this.selectedUser.new_password"><span>Alterar
            Senha</span> </soft-button-dark>
      </el-form>
      <template #footer>

      </template>
    </el-dialog>
  </div>
</template>

<script>
import secure_api from "../services/secure-api";
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
import SoftButtonDark from "@/components/SoftButtonDark.vue";
import showSwal from "/src/mixins/showSwal.js";

export default {
  name: "Rastreabilidade",
  components: {
    VueGoodTable,
    SoftButtonDark
  },
  data() {
    return {
      users: [],
      isModalVisible: false, // Controle de visibilidade do modal
      selectedUser: null,
      roles: [],
      columns: [
        { label: "Nome", field: "name", sortable: true },
        { label: "E-mail", field: "email", sortable: true },
        { label: "Função", field: "role", sortable: true },
      ],
    };
  },
  computed: {

  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    async handleRowClick(params) {
      console.log("linha clicada", params);
      let role = await secure_api.get(`${process.env.VUE_APP_API_BASE_URL}/users/${params.row.id}/roles`);
      console.log("role", role);
      params.row.role = role.data[0]?.name;
      this.openEditModal(params.row);
    },
    handleDeleted() {
      console.log("usuário deletado");
    },
    fetchUsers() {
      secure_api.get(`${process.env.VUE_APP_API_BASE_URL}/roles`)
        .then((response) => {
          console.log("Funções encontradas:", response.data);
          this.roles = response.data;
        })
        .catch((error) => {
          console.error("Erro ao buscar funções:", error);
        });
      secure_api
        .get(`${process.env.VUE_APP_API_BASE_URL}/users/all`)

        .then((response) => {
          const user_data = response.data;
          if (user_data && user_data.length > 0) {
            this.users = user_data;
            this.users.forEach(async (user) => {              
              user.role = user.roles[0] ? user.roles[0].name : "Sem função definida";
              user.role_id = user.roles[0] ? user.roles[0].id : null;
            });
            console.log("Usuários encontrados:", this.users);
          } else {
            console.error("Nenhum registro de rastreabilidade encontrado.");
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar usuários:", error);
        });
    },
    openAddModal() {
      this.isModalVisible = true;
    },
    openEditModal(user) {
      console.log("Abrindo modal de edição para o usuário com id", user);
      console.log("configurações: Roles: ", this.roles);
      this.selectedUser = user;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async saveChanges() {
      console.log("Salvando alterações para o usuário", this.selectedUser);
      try {
        let response = await secure_api
          .put(`${process.env.VUE_APP_API_BASE_URL}/user_manager/${this.selectedUser.id}`, {
            name: this.selectedUser.name,
            email: this.selectedUser.email,
            role_id: this.selectedUser.role_id,
          });

        let selectedRole = this.roles.find(role => role.id === this.selectedUser.role_id);
        this.selectedUser.role = selectedRole ? selectedRole.name : "Sem função definida";

        console.log("Usuário atualizado com sucesso", response);
        showSwal.methods.showSwal({
          type: "success",
          message: "Usuário atualizado com sucesso!",
          width: 500,
        });
      }
      catch (error) {
        console.error("Erro ao atualizar usuário:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Erro ao atualizar usuário!",
          width: 500,
        });
      };
      this.closeModal();
    },
    changePassword() {
      secure_api
        .put(`${process.env.VUE_APP_API_BASE_URL}/users/${this.selectedUser.id}/password`, {
          password: this.selectedUser.new_password,
        })
        .then((response) => {
          console.log("Senha alterada com sucesso", response);
          showSwal.methods.showSwal({
            type: "success",
            message: "Senha alterada com sucesso!",
            width: 500,
          });
          this.closeModal();
        })
        .catch((error) => {
          console.error("Erro ao alterar senha:", error);
          showSwal.methods.showSwal({
            type: "error",
            message: "Erro ao alterar senha!",
            width: 500,
          });
        });
    },
  },
};
</script>
