import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/';

// Criação de instância do Axios
const secure_api = axios.create({
  baseURL: API_URL, // Base URL para todas as requisições
});

// Interceptor para adicionar o token no cabeçalho de todas as requisições
secure_api.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("userF")); // Busca o token salvo no localStorage
    //console.log("TOKEN DE ACESSO:", token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Adiciona o token no cabeçalho
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default secure_api;
