<template>
    <el-dialog id="rastreabilidade_drafts" v-model="isVisible" :title="Rascunhos" width="1500px">
        <div>
            <h4>Rascunhos</h4>
            <vue-good-table
:columns="columns" :rows="drafts" :search-options="{ enabled: true }"
                :pagination-options="{ enabled: true, perPageDropdown: [10, 25, 50, 100] }"
                :sort-options="{ enabled: true }" :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: true,
                    disableSelectInfo: true
                }" @selected-rows-change="onSelectedRowsChange">
            </vue-good-table>
            <br>
            <el-tooltip content="Apagar " placement="top">
                <el-button type="danger" :disabled="selectedRows.length == 0" @click="deleteSelectedDrafts">
                    <font-awesome-icon icon="trash" />
                </el-button>
            </el-tooltip>
            <el-tooltip content="Carregar rascunho selecionado" placement="top">
                <el-button type="confirm" :disabled="selectedRows.length != 1" @click="handleSelect">
                    <font-awesome-icon icon="edit" />
                </el-button>
            </el-tooltip>
        </div>
    </el-dialog>
</template>

<script>
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import secure_api from "../../services/secure-api";


export default {
    name: 'RastreabilidadesDrafts',
    components: {
        VueGoodTable,
    },    
    props: {
        reload : {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            drafts: [],
            selectedRows: [],
            columns: [
                { label: 'Cliente', field: 'client', sortable: true },
                { label: 'Produto', field: 'product', sortable: true },
                { label: 'Data', field: 'data_geracao', sortable: true },
                { label: 'PA (Ponto de Acabado)', field: 'ref_sistema', sortable: true },
                { label: 'PV', field: 'pv', sortable: true },
                { label: 'OF', field: 'of', sortable: true, type: 'number' },
                { label: 'OP', field: 'op', sortable: true },
                { label: 'Data de Envio', field: 'data_enviado', sortable: true },
            ],
        };
    },
    computed: {
        isVisible: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
    },
    watch: {
        reload: {
            emidiate: true,
            handler(st) {
                console.log('Reload:', st);
                if (st) {
                    this.fetchDrafts();
                    this.$emit('reloaded');
                }
            }
        },
    },
    mounted() {
        this.fetchDrafts();
    },
    methods: {
        async fetchDrafts() {
            try {
                const response = await secure_api.get('busca/rastreabilidade_drafts');
                this.drafts = response.data;
            } catch (error) {
                console.error('Erro ao buscar drafts de rastreabilidades:', error);
            }
        },
        onSelectedRowsChange({ selectedRows }) {
            this.selectedRows = selectedRows;
            console.log('Linhas selecionadas:', this.selectedRows);
            console.log('Quantidade de linhas selecionadas:', this.selectedRows.length);
        },
        async deleteSelectedDrafts() {
            console.log('Apagando drafts selecionados:', this.selectedRows);
            try {
                const idsToDelete = this.selectedRows.map(row => row.id);
                await secure_api.delete('delete/rastreabilidade_drafts', { data: { ids: idsToDelete } });
                this.fetchDrafts(); // Refresh the table data
                this.selectedRows = []; // Clear the selected rows
                this.$message.success('Drafts apagados com sucesso!');
                this.fetchDrafts();
            } catch (error) {
                console.error('Erro ao apagar drafts de rastreabilidades:', error);
                this.$message.error('Erro ao apagar drafts.');
            }
        },
        handleSelect() {
            if (this.selectedRows.length === 1) {
                console.log('Linha selecionada:', this.selectedRows[0]);
                this.$emit('draft-selecionado', this.selectedRows[0]);
            } else {
                console.error('Erro: Nenhuma ou mais de uma linha selecionada.');
            }
        },
    },
};
</script>

<style>
/* Adicione estilos personalizados aqui, se necessário */
</style>
