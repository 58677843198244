<template>
    <div></div>
  </template>
  
  <script>
  import Swal from 'sweetalert2';
  
  export default {
    name: 'ConfirmDialog',
    props: {
      title: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
      confirmButtonText: {
        type: String,
        default: 'Sim, excluir!',
      },
      cancelButtonText: {
        type: String,
        default: 'Cancelar',
      },
    },
    methods: {
      show(callback) {
        let parentZIndex = Number(document.querySelector('.el-overlay').style.zIndex);
        Swal.fire({
          title: this.title,
          text: this.text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.confirmButtonText,
          cancelButtonText: this.cancelButtonText,
          didOpen: () => {
            // Força o SweetAlert2 para frente após a inicialização
            document.querySelector('.swal2-container').style.zIndex = parentZIndex + 1;
          }
        }).then((result) => {
          if (result.isConfirmed) {
            callback();
          }
        });
      }
    }
  };
  </script>
  
  <style scoped>
  /* Adicione estilos personalizados aqui, se necessário */
  </style>