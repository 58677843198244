<template>
  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
    <div class="container justify-content-between">
      <!-- Título com ênfase -->
      <router-link class="navbar-brand fw-bold fs-4" to="/dashboard">
        TekLegacy
      </router-link>
      
      <!-- Logotipo da TekSea alinhado à direita -->
      <router-link class="navbar-logo ms-auto" to="/dashboard">
        <img src="@/assets/img/teksea-logo.png" alt="TekSea Logo" height="30" />
      </router-link>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style scoped>
.navbar-brand {
  color: #0056b3;
  font-weight: bold;
  font-size: 1.25rem;
}

.navbar-brand:hover {
  color: #003d80;
}

.navbar-logo img {
  height: 30px;
}
</style>
